<template>
  <div class="animated fadeIn">
    <div class="container mb-5">
      <div class="card p-5 bg-white shadow">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1 class="h3">{{ $t('message.registration_confirmation') }}</h1>
            <p class="lead">
            </p>
          </div>
          <b-row>
            <b-col sm="12" v-if="registrationConfirmed">
              <b-alert show variant="success">
                {{registrationMessage}}
              </b-alert>
            </b-col>
            <b-col sm="12" v-if="registrationAlreadyConfirmed">
              <b-alert show variant="danger">
                {{registrationMessage}}<br/>
                <router-link
                  :to="{name : 'products'}"
                >{{$t('message.back_to_homepage')}}</router-link>
                {{$t('message.otherwise_text')}}
                <router-link to="/send-registration-token">{{$t('message.request_new_confirmation_token')}}</router-link>
              </b-alert>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import ApiResolver from '../components/common/ApiResolver';
  import Api from '../components/common/Api';
  import Loading from 'vue-loading-overlay';

  import store from '@/store';

  export default {
    name: 'register-confirm',
    components: {
      Loading
    },
    data() {
      return {
        isLoading: false,
        registrationConfirmed: false,
        registrationAlreadyConfirmed: false,
        registrationMessage: null
      };
    },
    props: [
      'query'
    ],
    computed: {},
    methods: {},
    mounted() {

      this.isLoading = true;
      Api
        .post(ApiResolver.get('REGISTER_CONFIRM'), JSON.stringify(this.$route.query))
        .then(response => {

          this.registrationConfirmed = true;
          this.registrationMessage = this.$t('message.register_successfull')
          this.isLoading = false;

          this.$store.dispatch('alerts/setAlert', {
            type: 'success',
            text: this.$t('message.register_successfull')
          });

          store.dispatch('user/logout');
          this.$router.push({name: 'login'});

        })
        .catch(reason => {
          this.registrationAlreadyConfirmed = true;

          this.$store.dispatch('alerts/setAlert', {
            type: 'danger',
            text: this.$t('message.confirmation_token_not_valid')
          });
          this.registrationMessage = this.$t('message.confirmation_token_not_valid');

          this.isLoading = false;
        });


    },
  };
</script>

<style scoped>
  .hasError label {
    color: red;
  }
</style>


